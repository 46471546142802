






































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import RentStockItem from "@/views/models/admin/RentStockItem";
import RentApi from "@/apis/stock/RentStockApi";
import { BaseRepo } from "@/models/BaseRepo";

@Component({
  components: { BaseForm, ValidationTextField, ValidationSelect },
})
export default class ReclaimRentForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: RentStockItem) {
    return data;
  }
  @Prop() reclaimItem!: RentStockItem;
  @Prop() StockType!: boolean;

  private rentApi: RentApi = new RentApi();

  private product_name = "";

  protected created() {
    console.log(this.reclaimItem);
  }
  private async save() {
    this.isConnecting = true;

    try {
      const repo = await this.rentApi.reclaim(this.reclaimItem);
      const data: any = repo.data as BaseRepo;
      this.submit(data);
    } catch (e) {
      this.handleError(e);
    } finally {
      this.isConnecting = false;
    }
  }
}
