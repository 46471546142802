






















































import RentApi, { RentUpdateParams } from "@/apis/stock/RentStockApi";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import BaseView from "@/views/BaseView.vue";
import RentStockStates from "@/consts/RentStockStates";
import RentStockItem from "@/views/models/admin/RentStockItem";
import { Component, Emit, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({
  components: { BaseForm, ValidationTextField, ValidationSelect },
})
export default class EditRentForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: RentStockItem) {
    return data;
  }

  @Prop() editedItem!: RentStockItem;

  @Prop() StockType!: boolean;

  private params!: RentUpdateParams;
  private rentApi: RentApi = new RentApi();
  private product_name = "";
  private states = RentStockStates;
  private isBrocken = false;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  protected created() {
    this.params = {
      id: this.editedItem.id,
      org_id: this.editedItem.org_id,
      state: this.editedItem.state,
      serial_number: this.editedItem.serial_number,
      remark: this.editedItem.remark,
    };

    this.product_name = `${this.editedItem.product.name}(${this.editedItem.product.model_name})`;

    this.isBrocken = this.params.state === "broken";
  }

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;

      try {
        const repo = await this.rentApi.update(this.params);
        const data = repo.data;
        this.submit(data);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isConnecting = false;
      }
    }
  }

  private onChangeBroken(checked: boolean) {
    this.params.state = checked ? "broken" : "ready";
  }
}
