import Vue from "vue";
import { RentStock, RentStockStateType } from "@/models/RentStock";
import RentStockItem from "@/views/models/admin/RentStockItem";

export default class RentStockApi {
  public readonly root: string = "stock/rents";

  public index(params: any) {
    return Vue.axios.get(this.root, { params });
  }

  public delete(id: number) {
    return Vue.axios.delete(this.root + "/" + id);
  }

  public create(rentStock: RentStock) {
    return Vue.axios.post(this.root, rentStock);
  }

  public update(rentStock: RentUpdateParams) {
    return Vue.axios.put(this.root + "/" + rentStock.id, rentStock);
  }

  public move(rentStockItems: RentStockItem) {
    return Vue.axios.put(
      `${this.root}/moves/${rentStockItems.id}/${rentStockItems.to}`,
      rentStockItems
    );
  }

  public rent(rentStockItems: RentStockItem) {
    return Vue.axios.put(
      `${this.root}/rents/${rentStockItems.id}/${rentStockItems.to}`,
      rentStockItems
    );
  }

  public reclaim(rentStockItems: RentStockItem) {
    return Vue.axios.put(
      `${this.root}/reclaim/${rentStockItems.org_id}/${rentStockItems.id}`,
      rentStockItems
    );
  }
}
export type RentUpdateParams = {
  id: number;
  org_id: number;
  state: RentStockStateType;
  serial_number: string;
  remark: string;
};

export type RentSearchParams = {
  org_category: number;
  org_id?: number;
  product_category_id: number;
  product_id: number;
  per_page: number;
  page: number;
};
