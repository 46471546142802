





















































































































































































































import ProductApi from "@/apis/product/ProductApi";
import RentApi, { RentSearchParams } from "@/apis/stock/RentStockApi";
import OrgCategory from "@/components/ui/OrgCategory.vue";
import OrgSearch from "@/components/ui/OrgSearch.vue";
import ProductCategories from "@/components/ui/ProductCategories.vue";
import { Org } from "@/models/Org";
import Pagenation from "@/models/Pagenation";
import { ProductCategory } from "@/models/Product";
import ArrayUtil from "@/utils/ArrayUtil";
import BaseView from "@/views/BaseView.vue";
import ProductItems from "@/views/models/admin/ProductItems";
import RentStockItem from "@/views/models/admin/RentStockItem";
import { Component } from "vue-property-decorator";
import CreateForm from "./forms/CreateRentForm.vue";
import EditForm from "./forms/EditRentForm.vue";
import MoveForm from "./forms/MoveRentForm.vue";
import RentForm from "./forms/RentForm.vue";
import ReclaimForm from "./forms/ReclaimRentForm.vue";

@Component({
  components: {
    CreateForm,
    EditForm,
    MoveForm,
    OrgSearch,
    OrgCategory,
    ProductCategories,
    ReclaimForm,
    RentForm,
  },
})
export default class Main extends BaseView {
  private productHeaders = [
    { text: "No", value: "id" },
    {
      text: "제품",
      align: "start",
      sortable: false,
      value: "product_name",
    },
    { text: "시리얼번호", value: "serial_number" },
    { text: "상태", value: "state" },
    { text: "위치", value: "rent_org.name" },
    { text: "기능", value: "actions", sortable: false },
    {
      text: "회수",
      value: "reclaim",
      sortable: false,
    },
  ];

  private isCreating = false;
  private isEditing = false;
  private isMoving = false;
  private isRent = false;
  private isReclaim = false;
  private selectedItem!: RentStockItem;

  private rentApi = new RentApi();
  private productApi = new ProductApi();

  private items: Array<RentStockItem> = [];
  private productCategoryTags: Array<ProductCategory> = [];
  private productTags: Array<ProductItems> = [];
  private selectedOrg!: Org | null;
  private pagenation = new Pagenation();
  private selectedCtegoryId = 0;

  private params: RentSearchParams = {
    org_category: 1,
    product_category_id: 0,
    product_id: 0,
    per_page: 10,
    page: 1,
  };

  protected created() {
    this.loadDatas(1);
  }

  private async loadDatas(page: number) {
    this.isConnecting = true;
    this.productTags = [];
    this.items = [];
    this.params.page = page;
    this.params.org_id = this.selectedOrg?.id;

    try {
      const repo = await this.rentApi.index(this.params);
      repo.data.rentStock.per_page = Number(repo.data.rentStock.per_page);

      this.pagenation = repo.data.rentStock;
      this.productTags = repo.data.productGroups;
      console.log(repo.data.productGroups);
      this.items = [...this.pagenation.data];
    } catch (e) {
      this.handleError(e);
    }
    this.isConnecting = false;
  }

  private returnData(data: Array<ProductCategory>) {
    this.productCategoryTags = data;
  }

  private onSelectOrgCategory(category_id: number) {
    this.selectedOrg = null;
    this.params.org_category = category_id;
    this.params.product_category_id = 0;
    this.params.product_id = 0;

    // 본사일경우 true
    if (category_id == 1) this.loadDatas(1);
  }

  private onSelectOrg(org: Org) {
    this.selectedOrg = { ...org };
    this.params.product_id = 0;
    this.params.product_category_id = 0;

    this.loadDatas(1);
  }

  private onSelectProductCategory(category_id: number) {
    this.params.product_id = 0;
    this.params.product_category_id = category_id ?? 0;

    this.loadDatas(1);
  }

  private onClickTag(data: ProductCategory) {
    this.params.product_id = 0;
    this.params.product_category_id = data?.id ?? 0;

    this.loadDatas(1);
  }

  private onClickTagSub(data: any) {
    this.params.product_id = data?.product_id ?? 0;
    this.params.product_category_id = data?.product?.product_category_id ?? 0;

    this.loadDatas(1);
  }

  private onClickCreated() {
    this.isCreating = true;
  }

  private createRentProduct(item: RentStockItem) {
    this.items = [...this.items, item];
    this.isCreating = false;
  }

  private onClickEdit(item: RentStockItem) {
    this.isEditing = true;
    this.selectedItem = { ...item };
  }

  private onProductUpdate(data: RentStockItem) {
    ArrayUtil.replaceById(this.items, data);
    this.isEditing = false;
  }

  private onClickMove(item: RentStockItem) {
    this.isMoving = true;
    this.selectedItem = { ...item };
  }

  private onProductMove(data: RentStockItem) {
    ArrayUtil.removeById(this.items, data);
    this.isMoving = false;
  }

  private onClickRent(item: RentStockItem) {
    this.isRent = true;
    this.selectedItem = { ...item };
  }

  private onProductRent(item: RentStockItem) {
    ArrayUtil.replaceById(this.items, item);
    this.isRent = false;
  }

  private onClickReclaim(item: RentStockItem) {
    this.$confirm("확인", "회수하시겠습니까?", async (result: boolean) => {
      if (!result) return;

      this.isConnecting = true;

      try {
        const repo = await this.rentApi.reclaim(item);

        //본사
        if (this.params.org_id === undefined) {
          ArrayUtil.replaceById(this.items, repo.data);
        } else {
          ArrayUtil.removeById(this.items, item);
        }
      } catch (e) {
        this.handleError(e);
      }

      this.isConnecting = false;
    });
  }

  private async onClickDelete(item: RentStockItem) {
    this.$confirm("확인", "삭제하시겠습니까?", async (result: boolean) => {
      if (!result) return;

      this.isConnecting = true;

      try {
        await this.rentApi.delete(item.id);
        ArrayUtil.removeById(this.items, item);
      } catch (e) {
        this.handleError(e);
      }

      this.isConnecting = false;
    });
  }
}
